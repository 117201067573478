import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BusinessCard = () => {
    
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/')
    })

    return (
        <div className='business-card'>
            
        </div>
    );
}

export default BusinessCard;