import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { MatchMediaProvider } from './context/MatchMedia';
import { UserProvider } from './context/UserContext';


// Assets
import './assets/fonts/men-nefer/Men-Nefer.ttf';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <UserProvider>
        <MatchMediaProvider>
            <App />
        </MatchMediaProvider>
    </UserProvider>
    
);

