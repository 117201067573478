import './Home.css';

// Components
import Header from '../components/home/Header';
import About from '../components/home/About';
import Menu from '../components/Menu';
import Contact from '../components/Contact';
import GoogleReviews from '../components/GoogleReviews';

const Home = () => {

    return (
        <div className="home">
            <Header />
            {/* <div className='news-flash'>
                <h1>Smaack is gesloten 17 september t/m 27 september</h1>
                <p>
                Beste klanten van Smaack Traiteur en Delicatessen,<br/><br/>

                We willen u graag informeren dat Smaack tijdelijk gesloten zal zijn van Zondag 17 september tot en met Woensdag 27 september.<br/>
                Wij waarderen uw begrip en geduld gedurende deze korte pauze. Zodra we onze deuren weer openen, verwelkomen we u met open armen en kijken we ernaar uit om u opnieuw te verwennen met onze smaackvolle gerechten en heerlijke producten.<br/><br/>

                Bedankt voor uw voortdurende steun. We hopen u snel weer te zien!<br/><br/>

                Met vriendelijke groet,<br/><br/>

                <span>Karin & Aziz</span>
                </p>
            </div> */}
            <About />
            <Menu />
            <GoogleReviews />
            <Contact />
        </div>
    );
}

export default Home;