export const capFirstChar = (string) => {
    const lowercase = string.toLowerCase();
    const firstUppercase = lowercase[0].toUpperCase();
    const value = firstUppercase + lowercase.substr(1);
    return value;
}

export const renderPrice = (value) => {
    const string = value.toString();
    const length = string.length;
    if(length === 1){
        return string === '0' ? 'Gratis' : `€0,0${string}`
    }
    else if(length === 2){
        return `€0,${string}`
    }
    else if(length > 2){
        const round = string.slice(0, -2);
        const cents = string.slice(-2);
        return cents === '00' ? `€${round},-` : `€${round},${cents}`
    }
}

export const renderPortion = (x, y) => {
    let amount = x;
    let type = y;

    if(x > 1 && y === 'Persoon'){
        type = 'Personen';
    }
    if(x > 1 && y === 'Stuk'){
        type = 'Stuks';
    }
    if(x == 1 && y === 'Personen'){
        amount = 'Per'
    }
    if(x == 1 && y === 'Stuk'){
        amount = 'Per'
    }

    return `${amount} ${type}`;
}

export const getCurrentDay = () => {
    const today = new Date(Date.now()).toLocaleDateString("nl-NL", {weekday: 'long'})
    return today;
}

export const getTomorrow = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    return new Date(tomorrow.setDate(tomorrow.getDate() + 1)).toLocaleDateString("nl-NL", { weekday: 'long' });
}

export const getDayAfterTomorrow = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    return new Date(tomorrow.setDate(tomorrow.getDate() + 2)).toLocaleDateString("nl-NL", { weekday: 'long' });
}

export const renderDate = (timestamp) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(timestamp * 1000).toLocaleDateString("nl-NL", options);
}

export const renderTime = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleTimeString("nl-NL");
}

export const getNextMonday = () => {
    const options = { weekday: 'short', month: 'long', day: 'numeric' };
    const nextMonday = new Date();
    nextMonday.setDate(nextMonday.getDate() + ((7 - nextMonday.getDay()) % 7 + 1) % 7);
    return nextMonday.toLocaleDateString("nl-NL", options);
}

export const getNextSunday = () => {
    const options = { weekday: 'short', month: 'long', day: 'numeric' };
    const nextSunday = new Date();
    nextSunday.setDate(nextSunday.getDate() + ((7 - nextSunday.getDay()) % 7 + 1) % 7 + 6);
    return nextSunday.toLocaleDateString("nl-NL", options);
}

// { weekday: 'short', month: 'long', day: 'numeric' };
export const getCurrentWeekday = (val = 'ma', options = { month: 'long', day: 'numeric' }) => {
    const days = ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'];
    const day = days.indexOf(val);
    const nextWeekDay = new Date();
    nextWeekDay.setDate(nextWeekDay.getDate() + ((7 - nextWeekDay.getDay()) % 7 + 1) % 7 + day - 7);
    return nextWeekDay.toLocaleDateString("nl-NL", options);
}

export const getNextWeekday = (val = 'ma', options = { month: 'long', day: 'numeric' }) => {
    const days = ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'];
    const day = days.indexOf(val);
    const nextWeekDay = new Date();
    nextWeekDay.setDate(nextWeekDay.getDate() + ((7 - nextWeekDay.getDay()) % 7 + 1) % 7 + day);
    return nextWeekDay.toLocaleDateString("nl-NL", options);
}

export const getCurrentWeekdayTimestamp = (val = 'ma') => {
    const date = getCurrentWeekday(val, { day: 'numeric', month: 'numeric', year: 'numeric' }).split('-');
    const newDate = [ date[1], date[0], date[2] ].join('/');
    return toTimestamp(`${newDate} 00:00:00`);
}

export const getNextWeekdayTimestamp = (val = 'ma') => {
    const date = getNextWeekday(val, { day: 'numeric', month: 'numeric', year: 'numeric' }).split('-');
    const newDate = [ date[1], date[0], date[2] ].join('/');
    return toTimestamp(`${newDate} 00:00:00`);
}

export const toTimestamp = (strDate) => {
    var datum = Date.parse(strDate);
    return datum/1000;
}

export const getWeekdayByNum = (val) => {
    const weekdays = ['maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag', 'zondag'];
    return weekdays[val];
}
