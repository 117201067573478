import './Footer.css';

import christmasOrderList from '../assets/orderlists/smaack-bestellijst-kerstmis.pdf';

// assets
import logo from '../assets/img/logo.png';

// react-router-dom
import { Link } from 'react-router-dom';

const Footer = () => {
    return(
        <div className='footer'>
            <div className='footer-menu'>
                <Link to='/'>Home</Link>
                <Link to='/over'>Over Ons</Link>
                <Link to='/menu'>Menukaart</Link>
                <Link to='/bestellijst'>Bestellijst</Link>
                <Link to='/contact'>Contact</Link>
            </div>
            <img src={logo} />
            <div className='social-media'>
                <a href='https://www.facebook.com/SmaackBreda/'>
                    <i className='fa-brands fa-facebook'></i>
                </a>
                <a href='https://www.instagram.com/traiteursmaack/'>
                    <i className='fa-brands fa-instagram'></i>
                </a>
            </div>
        </div>
    );
}

export default Footer;