import './AdminWeeklyMenu.css';

import { useState, useEffect, useLayoutEffect } from 'react';

import { Link } from 'react-router-dom';

// Firebase
import { db } from '../Firebase';
import { doc, collection, getDoc, getCollection, updateDoc, deleteField } from 'firebase/firestore';
import { capFirstChar, getCurrentDay, getDayAfterTomorrow, getNextWeekday, getTomorrow, renderPrice, getWeekdayByNum, getNextWeekdayTimestamp, getCurrentWeekdayTimestamp } from '../Functions';

const AdminWeeklyMenu = () => {

    const [currentMenu, setCurrentMenu] = useState([]);
    const [nextMenu, setNextMenu] = useState([]);
    const [emptyMenu, setEmptyMenu] = useState([]);

    useEffect(() => {
        for(let i = 0; i < 7; i++){
            emptyMenu.push({
                day: getWeekdayByNum(i),
                mealDescription: '',
                mealTitle: '',
                noMenu: true,
                price: null,
                timestamp: getNextWeekdayTimestamp(getWeekdayByNum(i).substring(0,2)),
            })
        }
        setEmptyMenu([...emptyMenu]);
    }, [])

    useEffect(() => {
        nextMenu && nextMenu.length > 0 &&
        compareTime();
    })

    let nowInSec = Math.round(Date.now()/1000);
    let monInSec = nextMenu[0]?.timestamp;
    const compareTime = () => {
        nowInSec > monInSec && replaceCurrentMenu();
        nowInSec > monInSec && fillNextWeeksMenu();

        // nowInSec > 1657369230 && replaceCurrentMenu();
        // nowInSec > 1657369230 && fillNextWeeksMenu();
    }

    const replaceCurrentMenu = async () => {
        const menuRef = doc(db, 'weeklyMenu', 'menu');
        await updateDoc(menuRef, {
            currentMenu: deleteField(),
        }).then(() => {
            updateDoc(menuRef, {
                currentMenu: [...nextMenu],
            })
        })
    }

    const fillNextWeeksMenu = async () => {
        const menuRef = doc(db, 'weeklyMenu', 'menu');
        await updateDoc(menuRef, {
            nextWeeksMenu: deleteField(),
        }).then(() => {  
            updateDoc(menuRef, {
                nextWeeksMenu: [...emptyMenu],
            })
        })
    }

    useEffect(() => {
        getCurrentMenu();
        getNextMenu();
    }, [])

    const getCurrentMenu = async () => {
        const menuRef = doc(db, 'weeklyMenu', 'menu');
        const menuSnap = await getDoc(menuRef);
        if(menuSnap.exists()) {
            const obj = {...menuSnap.data(), id: menuSnap.id};
            setCurrentMenu(obj.currentMenu);
        } else {
            console.log('no such document');
        }
    }

    useEffect(() => {
        console.log(currentMenu);
    }, [currentMenu])

    const getNextMenu = async () => {
        const menuRef = doc(db, 'weeklyMenu', 'menu');
        const menuSnap = await getDoc(menuRef);
        if(menuSnap.exists()) {
            const obj = {...menuSnap.data(), id: menuSnap.id};
            setNextMenu(obj.nextWeeksMenu);
        } else {
            console.log('no such document');
        }
    }

    useEffect(() => {
        console.log('current weekdays timestamp:')
        const currentTimeStamp = getNextWeekdayTimestamp(getDayAfterTomorrow().substring(0,2));
        console.log(currentTimeStamp);
        console.log(new Date(currentTimeStamp * 1000))
    }, [])

    return (
        <div className="admin-weekly-menu">
            <div className='current-dishes-container'>
                <div className='current-dishes'>
                    {
                        currentMenu?.map((menu) => {
                            return (
                                <>
                                {
                                    menu.timestamp === getCurrentWeekdayTimestamp(getCurrentDay().substring(0,2)) && !menu?.noMenu &&
                                    <div className='dish today'>
                                        <p className='today'>Vandaag</p>
                                        <h1>{menu.mealTitle}</h1>
                                        <p>{menu.mealDescription}</p>
                                        <p className='price'>{menu.price && renderPrice(menu.price)}</p>
                                    </div>
                                }
                                {
                                    menu.timestamp === getCurrentWeekdayTimestamp(getCurrentDay().substring(0,2)) && menu?.noMenu &&
                                    <div className='dish today no-menu'>
                                        <p className='today'>Vandaag</p>
                                        <h1>Geen dagschotel</h1>
                                        <p>&nbsp;</p>
                                    </div>
                                }
                                {
                                    menu.timestamp === getCurrentWeekdayTimestamp(getTomorrow().substring(0,2)) && !menu?.noMenu &&
                                    <div className='dish tomorrow'>
                                        <p className='not-today'>Morgen</p>
                                        <h1>{menu.mealTitle}</h1>
                                        <p>{menu.mealDescription}</p>
                                        <p className='price'>{menu.price && renderPrice(menu.price)}</p>
                                    </div>
                                }
                                {
                                    menu.timestamp === getCurrentWeekdayTimestamp(getTomorrow().substring(0,2)) && menu?.noMenu &&
                                    <div className='dish tomorrow no-menu'>
                                        <p className='not-today'>Morgen</p>
                                        <h1>Geen dagschotel</h1>
                                        <p>&nbsp;</p>
                                    </div>
                                }
                                {
                                    menu.timestamp === getCurrentWeekdayTimestamp(getDayAfterTomorrow().substring(0,2)) && !menu?.noMenu && nowInSec > monInSec &&
                                    <div className='dish day-after-tomorrow'>
                                        <p className='not-today'>{capFirstChar(getDayAfterTomorrow())}</p>
                                        <h1>{menu.mealTitle}</h1>
                                        <p>{menu.mealDescription}</p>
                                        <p className='price'>{menu.price && renderPrice(menu.price)}</p>
                                    </div>
                                }
                                {   
                                    menu.timestamp === getCurrentWeekdayTimestamp(getDayAfterTomorrow().substring(0,2)) && menu?.noMenu && nowInSec > monInSec &&
                                    <div className='dish day-after-tomorrow no-menu'>
                                        <p className='not-today'>{getDayAfterTomorrow()}</p>
                                        <h1>Geen dagschotel</h1>
                                        <p>&nbsp;</p>
                                    </div>
                                }
                                </>
                            );
                        })
                    }
                    {
                        nextMenu?.map((menu) => {
                            return (
                                <>
                                {
                                    getCurrentDay() === 'zondag' &&
                                    menu.timestamp === getNextWeekdayTimestamp(getTomorrow().substring(0,2)) && !menu?.noMenu &&
                                    <div className='dish day-after-tomorrow'>
                                        <p className='not-today'>Morgen</p>
                                        <h1>{menu.mealTitle}</h1>
                                        <p>{menu.mealDescription}</p>
                                        <p className='price'>{menu.price && renderPrice(menu.price)}</p>
                                    </div>
                                }
                                {   
                                    getCurrentDay() === 'zondag' &&
                                    menu.timestamp === getNextWeekdayTimestamp(getTomorrow().substring(0,2)) && menu?.noMenu &&
                                    <div className='dish tomorrow no-menu'>
                                        <p className='not-today'>Morgen</p>
                                        <h1>Geen dagschotel</h1>
                                        <p>&nbsp;</p>
                                    </div>
                                }
                                {
                                    getCurrentDay() === 'zaterdag' &&
                                    menu.timestamp === getNextWeekdayTimestamp(getDayAfterTomorrow().substring(0,2)) && !menu?.noMenu &&
                                    <div className='dish day-after-tomorrow'>
                                        <p className='not-today'>{capFirstChar(getDayAfterTomorrow())}</p>
                                        <h1>{menu.mealTitle}</h1>
                                        <p>{menu.mealDescription}</p>
                                        <p className='price'>{menu.price && renderPrice(menu.price)}</p>
                                    </div>
                                }
                                {   
                                    getCurrentDay() === 'zaterdag' &&
                                    menu.timestamp === getNextWeekdayTimestamp(getDayAfterTomorrow().substring(0,2)) && menu?.noMenu &&
                                    <div className='dish day-after-tomorrow no-menu'>
                                        <p className='not-today'>{capFirstChar(getDayAfterTomorrow())}</p>
                                        <h1>Geen dagschotel</h1>
                                        <p>&nbsp;</p>
                                    </div>
                                }
                                </>
                            );
                        })
                    }
                </div>
                <div className='buttons'>
                    <Link to='/admin/weekmenu/aanpassen' className='button'>Huidig weekmenu</Link>
                    <Link to='/admin/weekmenu/toevoegen' className='button'>Weekmenu {getNextWeekday('ma') + '/' + getNextWeekday('zo')}</Link>
                </div>
            </div>
        </div>
    );
}

export default AdminWeeklyMenu;