import "./OrderList.css";

import { useEffect, useState } from "react";

// assets
import christmasOrderList from "../assets/img/bestel-lijst-kerst-2024.pdf";

// Firebase
import { db } from "../Firebase";
import { doc, getDoc } from "firebase/firestore";

// Functions
import { renderPrice, renderPortion } from "../Functions";

// NPM
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

const OrderList = () => {
    const [orderList, setOrderList] = useState([]);

    // Get orderlist and portiontypes from firebase
    useEffect(() => {
        getOrderList();
    }, []);

    const getOrderList = async () => {
        const listRef = doc(db, "orderList", "listJuly22");
        const listSnap = await getDoc(listRef);
        if (listSnap.exists()) {
            const obj = { ...listSnap.data(), id: listSnap.id };
            setOrderList(obj.orderList);
        } else {
            console.log("no such document");
        }
    };

    // Generate PDF from orderList items
    const downloadPdfDocument = () => {
        var doc = new jsPDF("p", "pt", "letter");
        var pageHeight = 0;
        var width = doc.internal.pageSize.getWidth();
        pageHeight = doc.internal.pageSize.height;

        doc.setLineWidth(2);
        doc.text("Smaack bestellijst 2024", width / 2, 40, { align: "center" });
        doc.autoTable({
            html: "#orderList",
            startY: 70,
            theme: "grid",
            columnStyles: {
                0: {
                    cellWidth: 20,
                },
            },
            styles: {
                minCellHeight: 20,
            },
        });
        doc.save("smaack_bestellijst_2024.pdf");
    };

    const downloadChristmasOrderList = () => {
        const link = document.createElement("a");
        link.href = christmasOrderList;
        link.download = "smaack_bestellijst_kerstmis_2024.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="order-list">
            <h1 className="title">Bestellijst 2024</h1>
            <div className="buttons" style={{display: 'flex', gap: '15px'}}>
                <button
                    onClick={() => {
                        downloadPdfDocument();
                    }}
                >
                    Download reguliere bestellijst
                </button>
                <button
                    onClick={() => {
                        downloadChristmasOrderList();
                    }}
                    style={{ color: 'green', borderColor: 'green' }}
                >
                    Download de bestellijst voor kerst 2024
                </button>
            </div>
            <table id="orderList">
                <tbody>
                    {orderList.map((item) => {
                        return (
                            <tr>
                                <td className="checkbox-container">
                                    <div className="checkbox" />
                                </td>
                                <td>
                                    {item.title}{" "}
                                    {item.description && (
                                        <small>
                                            <i>({item.description})</i>
                                        </small>
                                    )}
                                </td>
                                <td>{renderPortion(item.amount, item.portionType)}</td>
                                <td>{renderPrice(item.price)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default OrderList;
