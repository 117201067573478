import './WeeklyMenu.css';

import { useEffect, useRef, useState, useContext } from 'react';

import { db } from '../Firebase';
import { doc, getDoc } from 'firebase/firestore';

// Context
import { UserContext } from '../context/UserContext';

// Assets
import logo from '../assets/img/logo.png';
import background from '../assets/img/weekmenu-bg.png';

import html2canvas from "html2canvas";
import { renderPrice } from '../Functions';

// const currentMenu = [
//     {
//         day: 'Dinsdag',
//         mealTitle: null,
//         mealDescription: null,
//         price: null,
//     },
//     {
//         day: 'Woensdag',
//         mealTitle: 'Ravioli 4 kazen',
//         mealDescription: 'met gehakt-tomatensaus',
//         price: 1000,
//     },
//     {
//         day: 'Donderdag',
//         mealTitle: 'Griekse gevulde aubergine',
//         mealDescription: 'met gehakt en Libanese rijst',
//         price: 1000,
//     },
//     {
//         day: 'Vrijdag',
//         mealTitle: 'Vegetarische stoofschotel',
//         mealDescription: 'met couscous',
//         price: 900,
//     },
//     {
//         day: 'Zaterdag',
//         mealTitle: 'Gevulde paprika',
//         mealDescription: 'met gehakt, rijst en salade',
//         price: 1000,
//     },
//     {
//         day: 'Zondag',
//         mealTitle: 'Gado gado',
//         mealDescription: 'met kip',
//         price: 900,
//     },
// ]

const WeeklyMenu = () => {

    const [userData] = useContext(UserContext);

    const [currentMenu, setCurrentMenu] = useState();
    const [loading, setLoading] = useState(true);

    const weeklyMenu = useRef();
    const element = weeklyMenu.current;
    const fileName = 'weekmenu';

    const handleDownloadImage = async () => {
        const canvas = await html2canvas(element, {
            onclone: function (clonedDoc) {
                clonedDoc.getElementById('download').style.display = 'block';
            },
            width: 1500,
            height: 1500,
        });
    
        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');
    
        if (typeof link.download === 'string') {
          link.href = data;
          link.download = `${fileName}.jpg`;
    
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          window.open(data);
        }
      };

    useEffect(() => {
        getCurrentMenu();
    },[])

    const getCurrentMenu = async () => {
        const menuRef = doc(db, 'weeklyMenu', 'menu');
        const menuSnap = await getDoc(menuRef);
        if(menuSnap.exists()) {
            const obj = {...menuSnap.data(), id: menuSnap.id};
            setCurrentMenu(obj.currentMenu);
            setLoading(false);
        } else {
            console.log('no such document');
        }
    }

    return (
        <div className="weekly-menu">
        {
            !loading &&
            <>
            <h1 className='title'>Weekmenu</h1>
            {/* <img src={background} alt='weekmenu background' className='background' /> */}
            <div className='download-menu' ref={weeklyMenu} id='download'>
            <img src={logo} alt='Smaack logo' className='logo' />
            <img src={background} alt='weekmenu background' className='background' />
                <div className='container'>
                    {
                        currentMenu?.map((item, i) => {
                            const menuReq = item?.mealTitle && item?.mealDescription && item?.price && !item.noMenu;
                            return (
                            i > 0 &&
                            <>
                                <div className='day'>
                                    {
                                    menuReq ?  
                                        <>
                                            <h1 className='brand-font'>{item?.day}</h1>
                                            <h2 className='title'>{item?.mealTitle}</h2>
                                            <p className='description'>{item?.mealDescription}</p>
                                            <p className='price'>{item?.price && renderPrice(item?.price)}</p>
                                        </> :
                                        <>
                                            <h1 className='brand-font'>{item?.day}</h1>
                                            <h1 className='brand-font'>Geen dagmenu</h1>
                                            <p>&nbsp;</p>
                                        </>
                                    }
                                </div>
                            </>
                            );
                        })
                    }
                </div>
            </div>
            <div className='week'>
                {
                    currentMenu.map((item, i) => {
                        const menuReq = item.mealTitle && item.mealDescription && item.price && !item.noMenu;
                        return (
                            i > 0 &&
                            <>
                            <div className='day'>
                                {
                                menuReq ?   
                                    <>
                                        <h1 className='brand-font'>{item?.day}</h1>
                                        <h2 className='title'>{item?.mealTitle}</h2>
                                        <p className='description'>{item?.mealDescription}</p>
                                        <p className='price'>{item?.price && renderPrice(item?.price)}</p>
                                    </> :
                                    <>
                                        <h1 className='brand-font'>{item?.day}</h1>
                                        <h1 className='brand-font'>Geen dagmenu</h1>
                                        <p>&nbsp;</p>
                                    </>
                                }
                            </div>
                            </>
                        );
                    })
                }
            </div>
            {userData && <button className="link-button" onClick={() => handleDownloadImage()} data-html2canvas-ignore>Download weekmenu</button>}
            </>
        }
        </div>
    );
}

export default WeeklyMenu;