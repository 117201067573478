import './NewsItem.css';

import { useRef } from 'react';

// lottie
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import christmasMenuAnimation from '../assets/animations/christmasMenuAnimation.json';

// assets
import image from '../assets/img/kerstmenu.png';

const NewsItem = () => {

    const christmasMenuRef = useRef<LottieRefCurrentProps>(null);

    return (
        <div className="news-item">
            <div className='animation-container'>
                <Lottie
                    className='animation'
                    animationData={christmasMenuAnimation}
                    lottieRef={christmasMenuRef}
                    loop={false}
                />
            </div>
            <div className='content'>
                <h3>Ons smaackvol kerstmenu van 2023 is bekend!</h3>
                <p style={{padding: '0 50px', textAlign: 'left'}}>
                    We verwelkomen uw bestellingen tot uiterlijk 15 december. U kunt deze op afspraak ophalen op zaterdag 23 december, zondag 24 december of op 25 december tot 14:00 uur.
                </p>
                <div className='buttons'>
                    <a href='tel:0646213341' className='button'><i className='fa-solid fa-phone'></i>Bel en reserveer nu!</a>
                    <a href="/assets/kerstmenu_2023.png" className='button' download><i className='fa-solid fa-file-arrow-down'></i>Download kerstmenu</a>
                </div>
            </div>
        </div>
    );
}

export default NewsItem;