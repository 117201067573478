import './PageNotFound.css'

import { Link } from 'react-router-dom';

import img from '../assets/img/not-found.png';

const PageNotFound = () => {
    return (
        <div className="page-not-found">
            <h1 className='brand-font'>Oops!</h1>
            <p>Deze pagina kunnen wij niet vinden. Het kan zijn dat de link verlopen is. Controleer de URL en probeer het later opnieuw.</p>
            <img src={img} alt='Pagina niet gevonden'/>
            <Link to='/'>Terug naar home</Link>
        </div>
    );
}
export default PageNotFound;