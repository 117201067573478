// React
import { useState, useEffect, useContext } from 'react';

// React-router-dom
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Context
import { UserContext } from './context/UserContext';

// Components
import Navbar from "./components/Navbar";
import Footer from './components/Footer';
import Alert from './components/Alert';

// Pages
import UnderConstruction from './components/UnderConstruction';
import Home from "./pages/Home";
import AboutPage from './pages/AboutPage';
import MenuPage from './pages/MenuPage';
import ContactPage from './pages/ContactPage';
import WeeklyMenu from './pages/WeeklyMenu';
import OrderList from './pages/OrderList';
import Login from './pages/Login';
import PageNotFound from './pages/PageNotFound';
import BusinessCard from './pages/BusinessCard';
import MenuExtras from './pages/MenuExtras';

// Admin
import AdminHub from './admin/AdminHub';
import AdminOrderList from './admin/AdminOrderList';
import EditOrderList from './admin/EditOrderList';
import AdminWeeklyMenu from './admin/AdminWeeklyMenu';
import AdminWeeklyMenuAdd from './admin/AdminWeeklyMenuAdd';
import AdminWeeklyMenuAdjust from './admin/AdminWeeklyMenuAdjust';

// AOS
import AOS from "aos";
import "aos/dist/aos.css";
import NewsItem from './pages/NewsItem';

const App = () => {

  const [alert, setAlert] = useState(false);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // Theme

  useEffect(() => {
    const preferedTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const theme = preferedTheme ? 'dark' : 'light';
    setTheme(theme);
  }, [])

  const [theme, setTheme] = useState('light');
  const themeToggle = () => {
    theme === 'dark' ? setTheme('light') : setTheme('dark');
  }

  const [userData] = useContext(UserContext);

  useEffect(() => {
    userData?.email && setAlert(true);
    console.log(userData);
  }, [userData])
  
  return (
    <div className="App" data-theme={theme}>
      <Router>
        <Navbar themeToggle={themeToggle} theme={theme} />
        <Routes>
          <Route path='/' element={ <Home /> } />
          <Route path='/smaack' element={ <AboutPage /> } />
          <Route path='/menu' element={ <MenuPage /> } />      
          <Route path='/contact' element={ <ContactPage /> } />
          <Route path='/weekmenu' element={ <WeeklyMenu /> } />
          <Route path='/bestellijst' element={ <OrderList /> } />
          <Route path='/login' element={ <Login /> } />
          <Route path='/kerstmenu' element={ <NewsItem /> } />
          {userData && <Route path='/admin' element={ <AdminHub/> } />}
          {userData && <Route path='/admin/bestellijst' element={ <AdminOrderList/> } />}
          {userData && <Route path='/admin/bestellijst/toevoegen' element={ <EditOrderList/> } />}
          {userData && <Route path='/admin/weekmenu' element={ <AdminWeeklyMenu /> } />}
          {userData && <Route path='/admin/weekmenu/toevoegen' element={ <AdminWeeklyMenuAdd /> } />}
          {userData && <Route path='/admin/weekmenu/aanpassen' element={ <AdminWeeklyMenuAdjust /> } />}
          <Route path='/paas-ontbijt' element={ <NewsItem /> } />
          <Route path='/qrcode' element={ <BusinessCard /> } />
          <Route path='/menukaart' element={ <MenuExtras /> } />
          <Route path='*' element={ <PageNotFound /> } />
        </Routes>
        <Footer/>
        <Alert alert={alert} setAlert={setAlert}>Ingelogd als:&nbsp;<i>{userData?.email}</i></Alert>
      </Router>

      {/* <UnderConstruction /> */}
    </div>
  );
}

export default App;
