import './MenuPage.css';

// Components
import Menu from "../components/Menu";

const MenuPage = () => {
    return (
        <div className='menu-page'>
            <Menu />
        </div>
    );
}

export default MenuPage;