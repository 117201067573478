import './GoogleReviews.css';

import{ useState, useEffect } from 'react';

// components
import LoadingSpinner from './LoadingSpinner';

// assets
import logo from '../assets/img/logo.png';

const GoogleReviews = () => {

    const [reviews, setReviews] = useState({});
    const [loading, setLoading] = useState(true);
    
    const placesApi = async () => {
        const locationID = 'ChIJm9BerHWhxkcRwSVjS7fPEKk';
        const url = `https://api.reviewsmaker.com/gmb/?placeid=${locationID}`;
        const result = await fetch(url).then((res) => res.json())
        console.log(result)
        setReviews(result)
        setLoading(false)
    }

    useEffect(() => {
    placesApi();
    },[])

    const renderReviewText = (text) => {
        let review;
        review = text.substr(0, 250);
        review = review.endsWith(' ') ? review.slice(0,-1) : review; 
        review = text.length > 250 ? review + '...' : review;
        return review;
    }

    return (
        !loading ?
        <div className='google-reviews'>
            <h1 className='google-reviews_title'>Een gemiddelde score van <span>{reviews.rating} / 5</span></h1>
            <p className='google-reviews_subtitle'>Gebasseerd op <span>{reviews.total_reviews}</span> Google reviews</p>
            <div className='list-container'>
                <div className='list'>

                    {
                        reviews.reviews.map((review, i) => {
                            return (
                                review.reviewRating > 3 && review.reviewText !== 'Only rating provided.' && review.reviewRating &&
                                <div className='review' key={i}>
                                    <img src={review.reviewAuthorImage ? review.reviewAuthorImage : logo} referrerPolicy="no-referrer" />
                                    <h1>{review.reviewAuthor}</h1>
                                    <p>{renderReviewText(review.reviewText)}</p>
                                    <div className='review-rating'>
                                        {[...Array(review.reviewRating)].map((_)=>(
                                            <i className='fa-solid fa-star filled'></i>
                                        ))}
                                        {review.reviewRating === 4 && <i className='fa-regular fa-star'></i>}
                                    </div>
                                    <a href={review.reviewUrl}>Bekijk review op google</a>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
        :
        <div className='google-reviews'>
            <h1 className='google-reviews_title loading'>Een gemiddelde score van <span>4.8</span> / 5</h1>
            <p className='google-reviews_subtitle loading'>Gebasseerd op <span>20</span> Google reviews</p>
            <div className='list-container'>
                <div className='list'>
                    {
                        [...Array(8)].map((_) => (
                            <div className='review loading' >
                                <LoadingSpinner />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default GoogleReviews;