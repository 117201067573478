import './ContactPage.css';

import Contact from "../components/Contact";

const ContactPage = () => {
    return (
        <div className="contact-page">
            <Contact />
        </div>
    );
}

export default ContactPage;