import './AdminHub.css';

import { useEffect, useContext } from 'react';

import { Link, useNavigate } from 'react-router-dom';

// Context
import { UserContext } from '../context/UserContext';

const adminOptions = [
    {
        title: 'Bestellijst',
        path: '/admin/bestellijst',
    },
    {
        title: 'Menukaart',
        path: '/admin/menu',
        disabled: true,
    },
    {
        title: 'Weekmenu',
        path: '/admin/weekmenu',
    },
    {
        title: 'Openingstijden',
        path: '/admin/openingstijden',
        disabled: true,
    },
    {
        title: 'Contactgegevens',
        path: '/admin/contact',
        disabled: true,
    },
];

const AdminHub = () => {

    const [userData] = useContext(UserContext);
    const navigate = useNavigate();
    useEffect(() => {
        !userData && navigate('/');
    })

    return (
        <div className="admin-hub">
            <h1>Admin Hub</h1>
            {
                adminOptions.map((option) => {
                    return (
                        <Link to={option.disabled ? '#' : option.path} className={option.disabled ? 'disabled' : ''}>{option.title}</Link>
                    );
                })
            }
        </div>
    );
}

export default AdminHub;