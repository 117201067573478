import './Header.css';

import { useContext, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { MatchMediaContext } from '../../context/MatchMedia';

// Assets
import placeholder1 from '../../assets/img/placeholder-img-1.jpg';
import weekmenuImage from '../../assets/img/weekmenu.png';
import placeholder2 from '../../assets/img/placeholder-img-2.jpg';
import placeholder3 from '../../assets/img/placeholder-img-3.jpg';
import placeholder4 from '../../assets/img/placeholder-img-4.jpg';
import PaasOntbijt from '../../assets/img/PAAS.png';
import menuImage from '../../assets/img/menu.png';
import bestellijst from '../../assets/img/bestellijst.png';
import kerstmenu from '../../assets/img/kerstmenu.png';
import kerstbestellijst from '../../assets/img/bestel-lijst-kerst-2024.pdf';

// Christmas 
import christmasDiner from '../../assets/img/kerstdiner.png';



const headerItems = [
    {
        title: 'Bestellijst kerstmis 2024',
        // content: 'Bekijk en download onze bestellijst om thuis te smullen!',
        content: 'De bestellijst voor kerst 2024 is nu beschikbaar!',
        img: christmasDiner,
        wideIsTrue: true,
        path: '/bestellijst',
    },
    {
        title: 'Over ons',
        content: 'Wie wij zijn en waar we voor staan',
        img: placeholder1,
        wideIsTrue: false,
        path: '/smaack',
    },
    {
        title: 'Contact',
        content: 'Hier vind je onze contactgegevens',
        img: placeholder3,
        wideIsTrue: false,
        path: '/contact',
    },
    {
        title: 'Ons Weekmenu',
        content: 'Zie hier wat wij deze week op het menu hebben!',
        img: weekmenuImage,
        wideIsTrue: true,
        path: '/weekmenu',
    },
    {
        title: 'Ons Menu',
        content: 'Bekijk hier onze smakelijke menukaart!',
        img: menuImage,
        wideIsTrue: false,
        path: '/menu',
    },
]

const Header = () => {

    const [matches] = useContext(MatchMediaContext);

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <div className='header'>
            {
                headerItems.map((item, i) => {
                    return (
                    !item.external ?
                        <Link 
                            to={item.path}
                            className={`item ${item.wideIsTrue && 'grid-col-span-2'}`}
                            data-aos={matches ? i % 2 == 0 ? 'flip-left' : 'flip-down' : 'flip-up'}
                            data-aos-delay={i+'00'}
                            data-aos-duration={400}
                        >
                            <img src={item.img} alt={item.title} />
                            <div className='item-overlay'></div>
                            <div className='content' >
                                <h1>{item.title}</h1>
                                <p>{item.content}</p>
                            </div>
                        </Link>
                        :
                        <a 
                            href={item.path}
                            className={`item ${item.wideIsTrue && 'grid-col-span-2'}`}
                            data-aos={matches ? i % 2 == 0 ? 'flip-left' : 'flip-down' : 'flip-up'}
                            data-aos-delay={i+'00'}
                            data-aos-duration={400}
                        >
                            <img src={item.img} alt={item.title} />
                            <div className='item-overlay'></div>
                            <div className='content' >
                                <h1>{item.title}</h1>
                                <p>{item.content}</p>
                            </div>
                        </a>
                    );
                })
            }
        </div>
    );
}

export default Header;