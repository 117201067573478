import './Menu.css';

// Functions
import { renderPrice } from '../Functions';

const menu = [
    {
        title: 'Koude Broodjes',
        items: [
            {
                name: 'Jonge Kaas',
                price: 350,
                description: null,
            },
            {
                name: 'Oude Kaas',
                price: 450,
                description: null,
            },
            {
                name: 'Tonijnsalade',
                price: 650,
                description: null,
            },
            {
                name: 'Gezond',
                price: 550,
                description: 'Kaas met sla, tomaat en komkommer',
            },
            {
                name: 'Smaack',
                price: 650,
                description: 'Geitenkaas met honing en walnoten',
            },
            {
                name: 'Spaanse Bocadio',
                price: 650,
                description: 'Tonijn met sla, kaas, tomaat, augurk, olijven en jalapeno',
            },
        ],
    },
    {
        title: 'Warme Broodjes',
        items: [
            {
                name: 'Hete Kip',
                price: 650,
                description: 'Kipfilet met paprika, verse kaas en pikante saus'
            },
            {
                name: 'Moors Gehakt',
                price: 750,
                description: 'Gekruid rundergehakt met paprika, ui en ei',
            },
            {
                name: 'Andalouse',
                price: 750,
                description: 'Kipfilet met paprika, gebakken ei, cheddar, sla, verse kaas en pikante saus',
            },
            {
                name: 'Andaluxe',
                price: 1000,
                description: 'Ons bekende broodje Andalouse maar dan extra gevuld. Inclusief friet'
            }
        ]
    },
    {
        title: 'Tapas',
        items: [
            {
                name: 'Albondigas',
                price: 650,
                description: '150 gram Spaanse gehaktballetjes van kalfsgehakt in tomatensaus',
            },
            {
                name: 'Mini tortilla\'s',
                price: 400,
                description: '2 kleine tortillas: kaas, ei, ui en aardappel',
            },
            {
                name: 'Empagnada kalfsgehakt',
                price: 650,
                description: '2 empagnada\'s: bladerdeeg gevuld met gekruid kalfgehakt',
            },
            {
                name: 'Empagnada groenten',
                price: 650,
                description: '2 empagnada\'s: bladerdeeg gevuld met gekruide groenten',
            },
            {
                name: 'Gevulde tomaat',
                price: 550,
                description: '2 tomaten gevuld met gebakken aubergine en geitenkaas',
            },
            {
                name: 'Kiptapa\'s',
                price: 650,
                description: '3 mini pita\'s gevuld met pikante kipfilet en paprika',
            },
            {
                name: 'Oosterse kipspiesjes',
                price: 600,
                description: '2 spiesjes met oosters gekruide kipfilet, licht pikant',
            },
            {
                name: 'Pikante garnalenspiesjes',
                price: 650,
                description: '2 spiesjes met garnalen, paprika, knoflook, room en pikante kruiden',
            },
            {
                name: 'Spanakopia',
                price: 750,
                description: '2 spanakopia\'s: Filodeeg gevuld met spinazie en feta',
            },
            {
                name: 'Veggie spiesjes',
                price: 500,
                description: '2 spiesjes met tomaat, groene olijven, zwarte olijven, olijven gevuld met amandel en pesto olie',
            },
            {
                name: 'Olijvenmix',
                price: 500,
                description: '150 gram',
            },
            {
                name: 'Dipmix met brood',
                price: 750,
                description: 'baba Ganouche, paprikadip, dokkumerdip',
            },
        ]
    },
    {
        title: 'Tapas Verrassingsmenu',
        items: [
            {
                name: '2 gangen',
                price: 2250,
                description: 'Prijs per persoon'
            },
            {
                name: '3 gangen',
                price: 2900,
                description: 'Prijs per persoon',
            },
            {
                name: '4 gangen',
                price: 3500,
                description: 'Prijs per persoon',
            },
            {
                name: 'Kindermenu',
                price: 1750,
                description: 'Prijs per persoon'
            }
        ]
    },
    {
        title: 'Dessert',
        items: [
            {
                name: 'Cheesecake',
                price: 500,
            },
            {
                name: 'Creme brûlée',
                price: 550,
            },
            {
                name: 'Churros',
                price: 550,
            },
            {
                name: 'Vanille ijs met slagroom',
                price: 750,
            },
            {
                name: 'Flensje met ijs en slagroom',
                price: 750,
            },
            {
                name: 'Verrassingsplateau voor 2 personen',
                price: 1500,
            },
            {
                name: 'Kaasplankje voor 2 personen',
                price: 1500,
                description: '3 verschillende soorten kazen',
            },
        ]
    },
]


const Menu = () => {


    return (
        <div className='menu-container'>
            <h1>Menukaart</h1>
            <div className='menu'>
                {
                    menu.map((section) => {
                        return (
                            <div className='menu-section' key={section.title}>
                                <h1>{section.title}</h1>
                                <table>
                                    {
                                        section.items.map((item, i) => {
                                            return (
                                                <div 
                                                    className='menu-item'
                                                    key={`${item.title}-${i}`} 
                                                    data-aos={i % 2 == 0 ? 'fade-left' : 'fade-right'} 
                                                    data-aos-once={true} 
                                                    data-aos-delay={i+'00'}
                                                >
                                                    <tr>
                                                        <td>{item.name}</td>
                                                        <td>{renderPrice(item.price)}</td>
                                                    </tr>
                                                    {item.description && <p className='item-description'>{item.description}</p>}
                                                </div>
                                            );
                                        })
                                    }
                                </table>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default Menu;