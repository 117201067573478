import './Navbar.css';

// React
import { useEffect, useState, useContext } from 'react';


// Context
import { UserContext } from '../context/UserContext';

// React-router-dom
import { Link, useLocation, useNavigate } from 'react-router-dom';

// FIREBASE
import { auth } from '../Firebase';
import { signOut } from 'firebase/auth';

import christmasOrderList from '../assets/orderlists/smaack-bestellijst-kerstmis.pdf';

const navbarItems = [
    {
        item: 'Home',
        path: '/',
    },
    {
        item: 'About',
        path: '/smaack',
    },
    {
        item: 'Menu',
        path: '/menu',
    },
    {
        item: 'Contact',
        path: '/contact',
    },
    {
        item: 'Admin',
        path: '/admin',
        admin: true,
    },
]

const Navbar = ({ themeToggle, theme }) => {

    const [userData] = useContext(UserContext);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        console.log(location.pathname)
    }, [location])

    // Navbar Small Toggle
    const [navActive, setNavActive] = useState('inactive');
    const [navToggleIcon, setNavToggleIcon] = useState('fa-bars');

    const navbarSmallToggle = () => {
        navActive === 'active' ? setNavActive('inactive') : setNavActive('active');
        navActive === 'active' ? setNavToggleIcon('fa-bars') : setNavToggleIcon('fa-times');
    }

    const logout = async () => {
        await signOut(auth);
        navigate('/');
    }

    return (
        <>
        <p className='logo-text brand-font logo-mobile-top'>SMAACK</p>
        <nav className='navbar' data-aos='fade-down' data-aos-duration={750} data-aos-delay={500}>
        <div className='logo-text'>   
            <p className='brand-font'>SMAACK<small>.app</small></p>
            {userData && <p className='logged-in-as'>ingelogd als: {userData?.email}</p>}
        </div>
            <ul>
                {
                    !userData ?
                    navbarItems.map((x) => {
                        return (
                        !x.admin && 
                        <Link to={x.path} key={x.item} className={x.path === location.pathname && 'active'}>{x.item}</Link>
                        )
                    }) :
                    navbarItems.map((x) => {
                        return <Link to={x.path} key={x.item} className={x.path === location.pathname && 'active'}>{x.item}</Link>;
                    })
                }
                { userData && <a onClick={logout} className='logout'>Uitloggen</a> }
                <i className={`fa-solid fa-${theme === 'dark' ? 'sun' : 'moon'} theme-toggle`} onClick={themeToggle} ></i>
            </ul>
        </nav>
        <nav className={`navbar-small ${navActive}`}>
            <ul>
                {
                    !userData ?
                    navbarItems.map((x) => {
                        return !x.admin && <Link to={x.path} key={x.item} onClick={navbarSmallToggle}npm >{x.item}</Link>
                    }) :
                    navbarItems.map((x) => {
                        return <Link to={x.path} key={x.item} onClick={navbarSmallToggle}npm >{x.item}</Link>
                    })
                }
                { userData && <Link to='/admin'>Admin</Link> }
                { userData && <a onClick={logout} className='logout'>Uitloggen</a> }
                <i className='fa-solid fa-lightbulb theme-toggle' onClick={themeToggle} ></i>
            </ul>
        </nav>
        <button className={`navbar-toggle ${navActive}`} onClick={navbarSmallToggle} >
            <i className={`fa-solid ${navToggleIcon}`}></i>
        </button>
        </>
    );
}

export default Navbar;