import './EditOrderList.css';

import { useEffect, useLayoutEffect, useState, useContext } from 'react';

import { useNavigate } from 'react-router-dom';
// Functions
import { renderPortion, renderPrice } from '../Functions'; 

// Context
import { UserContext } from '../context/UserContext';

// Firebase
import { db } from '../Firebase';
import { updateDoc, arrayUnion, doc, getDoc } from 'firebase/firestore';

import LoadingSpinner from '../components/LoadingSpinner';
import Alert from '../components/Alert';


const FirstSection = ({ changeHandler, orderItem }) => {
    return (
        <>
        <div className='form-section'>
            <label>Naam van hoofdonderdeel gerecht</label>
            <input type='text' onChange={(e) => {changeHandler(e.target.value, 'title')}} value={orderItem.title} />
        </div>
        <div className='form-section'>
            <label>Bijkomende beschrijving van het gerecht</label>
            <input type='text' onChange={(e) => {changeHandler(e.target.value, 'description')}} value={orderItem.description} />
        </div>
        </>
    );
}

const SecondSection = ({ changeHandler, orderItem, portionTypes }) => {
    return (
        <>
            <div className='form-section'>
                <label>Portieverdeling per</label>
                <select onChange={(e) => {changeHandler(e.target.value, 'portionType')}} value={orderItem.portionType} >
                    <option value=''>-</option>
                    {
                        portionTypes?.map((type) => {
                            return (
                                <option value={type}>{type}</option>
                            );
                        })
                    }
                </select>
            </div>
            <div className='form-section'>
                <label>Portieverdeling aantal</label>
                <input type='number' onChange={(e) => {changeHandler(e.target.value, 'amount')}} value={orderItem.amount} />
            </div>
            <div className='form-section'>
                <label>Prijs</label>
                <input type='number' onChange={(e) => {changeHandler(e.target.value, 'price')}} value={orderItem.price} />
            </div>
        </>
    );
}

const EditOrderList = () => {

    // SEND USER BACK IF NOT LOGGED IN
    const [userData] = useContext(UserContext);
    const navigate = useNavigate();
    useEffect(() => {
        !userData && navigate('/');
    })

    const [currentSection, setCurrentSection] = useState(1);
    const [portionTypes, setPortionTypes] = useState([]);
    const [itemArray, setItemArray] = useState([]);
    const [alert, setAlert] = useState(false);
    const [alertItem, setAlertItem] = useState('');

    const [orderItem, setOrderItem] = useState({
        title: '',
        description: '',
        portionType: '',
        amount: null,
        price: null,
    })

    // Get orderlist and portiontypes from firebase
    useEffect(() => {
        getOrderList();
    },[])

    const getOrderList = async () => {
        const portionTypeRef = doc(db, 'orderList', 'portionTypes');
        const portionTypeSnap = await getDoc(portionTypeRef);
        if(portionTypeSnap.exists()) {
            const obj = {...portionTypeSnap.data(), id: portionTypeSnap.id};
            setPortionTypes(obj.types);
        } else {
            console.log('no such document');
        }
    }

    // Add single object to firebase
    const addItemToDb = async () => {
        setAlertItem(orderItem.title);
        setAlert(true);
        const listRef = doc(db, 'orderList', 'listJuly22');
        await updateDoc(listRef, {
            orderList: arrayUnion({...orderItem})
        });
        setOrderItem({
            title: '',
            description: '',
            portionType: '',
            amount: null,
            price: null,
        });
        setCurrentSection(1);
        setItemArray([]);
    }

    // Add array to firebase
    const addArrayToDb = async () => {
        setAlertItem(`${itemArray.length} artikelen`);
        setAlert(true);
        const listRef = doc(db, 'orderList', 'listJuly22');
        await updateDoc(listRef, {
            orderList: arrayUnion(...itemArray)
        });
        setOrderItem({
            title: '',
            description: '',
            portionType: '',
            amount: null,
            price: null,
        });
        setCurrentSection(1);
        setItemArray([]);
    }

    // Handle input changes
    const changeHandler = (value, key) => {
        setOrderItem({
            ...orderItem,
            [key]: value
        })
    }

    // Add order item to array
    const addAnotherItem = () => {
        itemArray.push(orderItem);
        setOrderItem({
            title: '',
            description: '',
            portionType: '',
            amount: null,
            price: null,
        });
        setCurrentSection(1);
    }

    // Remove item from array
    const removeItemFromArray = (index) => {
        const arr = itemArray;
        arr.splice(index, 1);
        setItemArray([...arr]);
        setCurrentSection(currentSection);
    }

    useEffect(() => {
        console.log('itemArray:');
        console.log(itemArray);
    }, [itemArray])

    // Conditions for saving and uploading an item
    const saveReq = orderItem.title && orderItem.amount && orderItem.portionType && orderItem.price;

    return (
        <div className="edit-order-list">
            <h1 className='title'>Artikelen aan bestellijst toevoegen</h1>
            <div className='item-example-array'>
            {
                itemArray.map((item, i) => {
                    return <p className='description'>{item.title} {item.description && `(${item.description})`} <i className='fa-solid fa-times' onClick={() => removeItemFromArray(i)}></i></p>
                })              
            }
            </div>
            <div className='item-example'>
                {saveReq && <i className='fa-solid fa-square-check'></i>}
                {orderItem.title && <p className='description'>{orderItem.title} {orderItem.description && `(${orderItem.description})`}</p>}
                {orderItem.amount && <p className='portion'>{renderPortion(orderItem.amount, orderItem.portionType)}</p>}
                {orderItem.price && <p className='price'>{orderItem.price && renderPrice(orderItem.price)}</p>}
            </div>
            <div className='form'>
                {currentSection === 1 && <FirstSection changeHandler={changeHandler} orderItem={orderItem}/>}
                {currentSection === 2 && <SecondSection changeHandler={changeHandler} orderItem={orderItem} portionTypes={portionTypes}/>}
            </div>
            <div className='buttons'>
                <div className='page'>
                    {<button className={`left ${currentSection === 1 && 'disabled'}`} onClick={() => setCurrentSection(currentSection - 1)} disabled={currentSection === 1}><i className='fa-solid fa-chevron-left'></i></button>}
                    {<button className={`right ${currentSection === 2 && 'disabled'}`} onClick={() => setCurrentSection(currentSection + 1)} disabled={currentSection === 2}><i className='fa-solid fa-chevron-right'></i></button>}
                </div>
                <div className='wrap-up'>
                    {itemArray.length < 1 && <button className={`save ${!saveReq && 'disabled'}`} disabled={!saveReq} onClick={() => {addItemToDb()}}>Artikel Opslaan</button>}
                    {itemArray.length > 0 && <button className={`save`} onClick={() => {addArrayToDb()}}>Lijst Opslaan</button>}
                    {<button className={`add ${!saveReq && 'disabled'}`} disabled={!saveReq} onClick={() => {addAnotherItem()}}><i className='fa-solid fa-plus'></i> Nog een artikel</button>}
                </div>
            </div>
            <Alert alert={alert} setAlert={setAlert}>
                {alertItem} toegevoegd!
            </Alert>
        </div>
    );
}

export default EditOrderList;