import './Login.css';

import { useState, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

// FIREBASE AUTH
import { auth } from '../Firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';

// Context
import { UserContext } from '../context/UserContext';

// components
import Alert from '../components/Alert';

const Login = () => {

    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const [userData] = useContext(UserContext);
    
    // USER AUTH CHECK && NAVIGATE
    const navigate = useNavigate();
    useEffect(() => {
        userData?.email && navigate('/');
    })

    // LOGIN
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const changeHandler = (state, e) => {
        state(e.target.value)
    }

    const loginHandler = async () => {
        try {
            const userLogin = await signInWithEmailAndPassword(auth, email, password);
            console.log(userLogin);
        } catch (error) {
            console.log(error.message);
            error.message === 'Firebase: Error (auth/user-not-found).' && setAlertMessage('Dit email adres kunnen wij niet vinden');
            error.message === 'Firebase: Error (auth/wrong-password).' && setAlertMessage('Dit wachtwoord is niet correct');
            setAlert(true);
        }
    }

    return (
        <div className="login">
            <div className='form'>
                <div className='form-section'>
                    <label htmlFor='email' >Email</label>
                    <input type='text' name='email' onChange={(e) => {changeHandler(setEmail, e)}}/>
                </div>
                <div className='form-section'>
                    <label htmlFor='password'>Wachtwoord</label>
                    <div className='password-input'>
                        <input type={showPassword ? 'text' : 'password'} name='password' onChange={(e) => {changeHandler(setPassword, e)}} />
                        <i className='fa-solid fa-eye' onClick={() => toggleShowPassword()}></i>
                    </div>
                </div>
                <div className='horizontal-rule'/>
                <button className='primary' onClick={loginHandler}>Log in</button>
                <button className='secondary'>Wachtwoord vergeten?</button>
            </div>
            <Alert alert={alert} setAlert={setAlert}>
                {alertMessage}
            </Alert>
        </div>
    );
}

export default Login;