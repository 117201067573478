import './AdminWeeklyMenuAdd.css'

import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getCurrentWeekday, getNextWeekdayTimestamp, getWeekdayByNum, renderPrice } from '../Functions';

// Firebase
import { db } from '../Firebase';
import { updateDoc, arrayUnion, doc, getDoc } from 'firebase/firestore';
import LoadingSpinner from '../components/LoadingSpinner';

const AdminWeeklyMenuAdjust = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [daysChecked, setDaysChecked] = useState([]);
    const [daysMin, setDaysMin] = useState([]);
    const [checkModalHidden, setCheckModalHidden] = useState(true);

    useEffect(() => {
        window.scrollTo(0,0);
        fillDaysChecked();
        fillEmptyWeeklyMenu();
        getDaysChecked();
        setLoading(false);
    }, [])

    const fillDaysChecked = () => {
        for(let i = 0; i < 7; i++){
            daysChecked.length < 7 &&
            daysChecked.push(false);
            daysMin.push(false);
            setDaysChecked(daysChecked);
        }
    }

    const getDaysChecked = () => {
        for(let i = 0; i < daysChecked.length; i++){
            daysChecked[i] = !weeklyMenu[i]?.noMenu;
            setDaysChecked([...daysChecked]);
        }
    }

    const handleCheckBoxes = (index) => {
        daysChecked[index] = !daysChecked[index];
        weeklyMenu[index] = {
            ...weeklyMenu[index],
            noMenu: !daysChecked[index],
        }
        setWeeklyMenu([...weeklyMenu]);
        setDaysChecked([...daysChecked]);
    }

    const handleDaysMinChange = (index) => {
        daysMin[index] = !daysMin[index];
        setDaysMin([...daysMin]);
    }

    const [weeklyMenu, setWeeklyMenu] = useState([]);

    const fillEmptyWeeklyMenu = async () => {
        const menuRef = doc(db, 'weeklyMenu', 'menu');
        const menuSnap = await getDoc(menuRef);
        if(menuSnap.exists()) {
            const obj = {...menuSnap.data(), id: menuSnap.id};
            setWeeklyMenu(obj.currentMenu);
        } else {
            alert('Database object for this weeks menu not found')
        }
    }

    useEffect(() => {
        console.log(weeklyMenu)
    }, [weeklyMenu])

    const handleWeeklyMenuChange = (index, key, e) => {
        weeklyMenu[index] = {
            ...weeklyMenu[index],
            [key]: e.target.value,
        }
        setWeeklyMenu([...weeklyMenu]);
    }

    const updateNextWeek = async () => {
        setLoading(true);
        const menuRef = doc(db, 'weeklyMenu', 'menu');
        await updateDoc(menuRef, {
            currentMenu: [...weeklyMenu],
        }).then(() => {
            setCheckModalHidden(true);
            setWeeklyMenu([]);
            setDaysChecked([]);
            setDaysMin([]);
            fillDaysChecked();
            fillEmptyWeeklyMenu();
            setLoading(false);
        }).then(() => {
            navigate('/admin/weekmenu');
        })
    }

    useEffect(() => {
        console.log('days checked');
        console.log(daysChecked);
        console.log('days minimized');
        console.log(daysMin);
    })

    return (
        <>{
        !loading ?
        <div className="add-weekly-menu">
            <div className='form'>
            {
                daysChecked.map((x, i) => {
                    return (
                        <div className='section'>
                            <div className='day'>
                                <h1>{getWeekdayByNum(i)} <span>({getCurrentWeekday(getWeekdayByNum(i).substring(0,2))})</span></h1>  
                                <label class="switch">
                                    <input type="checkbox" onChange={() => {handleCheckBoxes(i)}} checked={!weeklyMenu[i]?.noMenu}/>
                                    <span class="slider round"></span>
                                </label>
                                <i className={`fa-solid ${daysMin[i] ? 'fa-angle-up' : 'fa-angle-down'} minimize-icon`} onClick={() => {handleDaysMinChange(i)}}></i>
                            </div>
                            <div className={`inputs ${!daysMin[i] && 'hidden'}`}>
                                <div className='input-section'>
                                    <label>Gerecht</label>
                                    <input type='text' onChange={(e) => {handleWeeklyMenuChange(i, 'mealTitle', e)}} value={weeklyMenu[i]?.mealTitle}/>
                                </div>
                                <div className='input-section'>
                                    <label>Beschrijving</label>
                                    <input type='text' onChange={(e) => {handleWeeklyMenuChange(i, 'mealDescription', e)}} value={weeklyMenu[i]?.mealDescription} />
                                </div>
                                <div className='input-section'>
                                    <label>Prijs</label>
                                    <input type='number' onChange={(e) => {handleWeeklyMenuChange(i, 'price', e)}} value={weeklyMenu[i]?.price && weeklyMenu[i]?.price} />
                                </div>
                            </div>
                        </div>
                    );
                })
            }
            </div>
            <button className='primary' onClick={() => {setCheckModalHidden(false)}}>Controleren</button>
            <div className={`modal-overlay ${checkModalHidden && 'hidden'}`}/>
            <div className={`modal check-modal ${checkModalHidden && 'hidden'}`}>
            <i className='fa-solid fa-times close-modal-icon' onClick={() => {setCheckModalHidden(true)}}></i>
                {
                    weeklyMenu.map((m, i) => {
                        const condition = m.mealTitle !== '' && m.price && !m.noMenu;
                        return (
                            condition ?
                            <div className='daily-menu'>
                                <p><span className='check-day'>{getWeekdayByNum(i)}:</span></p>
                                <p>{m.mealTitle} <small>{m.mealDescription}</small> <small><i>voor</i></small> {m.price && renderPrice(m.price)}</p>
                            </div> :
                            <div className='daily-menu no-menu'>
                                <p><span className='check-day'>{getWeekdayByNum(i)}:</span></p>
                                <p>géén dagschotel</p>
                            </div>
                        );
                    })
                }
            <div className='buttons'>
                <button className='secondary' onClick={() => {setCheckModalHidden(true)}}>Bewerken</button>
                <button className='primary' onClick={() => {updateNextWeek()}}>Opslaan</button>
            </div>
            </div>
        </div> :
        <div className='add-weekly-menu'>
            <LoadingSpinner />
        </div>
        }</>
    );
}

export default AdminWeeklyMenuAdjust;