import './AboutPage.css';

// Componenets
import About from '../components/home/About';

const AboutPage = () => {
    return (
        <div className='about-page'>
            <About />
        </div>
    );
}

export default AboutPage;