import './Contact.css';

const Contact = () => {
    return (
        <div className='contact'>
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2478.998817767293!2d4.773224734532217!3d51.58658504764266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c69f8756559539%3A0x706d06a25647de88!2sHoutmarkt%2037%2C%204811%20JE%20Breda!5e0!3m2!1snl!2snl!4v1655721834313!5m2!1snl!2snl"  
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade">
            </iframe>
            <div className='address'>
                <h1>Contact</h1>
                <a href='tel:0646213341'><i className='fa-solid fa-phone'></i>06-46213341</a>
                <a href='mailto:traiteursmaack@gmail.com'><i className='fa-solid fa-envelope'></i>traiteursmaack@gmail.com</a>
                <a href={`https://www.google.nl/maps/place/houtmarkt+37,+4811JE+Breda`}>
                    <i className='fa-solid fa-house'></i>Houtmarkt 37 4811JE Breda
                </a>
            </div>
        </div>
    );
}

export default Contact;