import './About.css';

// react
import { useEffect, useRef } from 'react';

// assets
import img from '../../assets/img/karin-en-aziz.jpg';

// gsap
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger)

const text = 'Smaack is een traiteur- en delicatessenwinkel in winkelstraat de Houtmarkt. De winkel heeft alles in huis voor liefhebbers van eten en drinken. Je koopt er verse kruiden en oliën tot originele producten, voor jezelf of om cadeau te geven. Eigenaars Karin en Aziz denken graag met je mee. De winkel heeft dagelijks verse maaltijden en daghappen. Ook voor huisgemaakte tapas, wijnen en sangria ben je bij Smaack aan het juiste adres.'

const About = () => {

    const aboutRef = useRef(null);
    const aboutTitleRef = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
        const aboutTitleElement = aboutTitleRef.current;
        gsap.from(aboutTitleElement, {x: -400, scrollTrigger: {
            trigger: aboutRef.current,
            start: '20% bottom',
            end: '90% bottom',
            scrub: 2,
        }})
    },[])

    useEffect(() => {
        const imageElement = imageRef.current;
        gsap.from(imageElement, {y: 200, scale: 0.8, scrollTrigger: {
            trigger: aboutRef.current,
            start: '20% bottom',
            end: '90% bottom',
            scrub: 2,
        }})
    },[])

    return (
        <div className="about" ref={aboutRef}>
            <div className='text'>
                <h1 ref={aboutTitleRef} >Over Smaack</h1>
                <p>{text}</p>
            </div>
            <img ref={imageRef} src={img} alt='Eigenaars Karin & Aziz'/>
        </div>
    );
}

export default About;