import './AdminOrderList.css'

import { useEffect, useState, useContext } from 'react';

import { Link, useNavigate } from 'react-router-dom';

// Components
import Alert from '../components/Alert';

// Context
import { UserContext } from '../context/UserContext';

// Firebase
import { db } from '../Firebase';
import { doc, getDoc, updateDoc, arrayRemove } from 'firebase/firestore';

// Functions
import { renderPrice, renderPortion } from '../Functions';

const AdminOrderList = () => {

    const [alert, setAlert] = useState(false);

    const [userData] = useContext(UserContext);
    const navigate = useNavigate();
    useEffect(() => {
        !userData && navigate('/');
    })

    const [orderList, setOrderList] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    // Get orderlist and portiontypes from firebase
    useEffect(() => {
        getOrderList();
    },[])

    const getOrderList = async () => {
        const listRef = doc(db, 'orderList', 'listJuly22');
        const listSnap = await getDoc(listRef);
        if(listSnap.exists()) {
            const obj = {...listSnap.data(), id: listSnap.id};
            setOrderList(obj.orderList);
        } else {
            console.log('no such document');
        }
    }

    // Delete item
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteItemObject, setDeleteItemObject] = useState({});
    const [deleteItemName, setDeleteItemName] = useState('');

    const deleteItem = (item) => {
        setDeleteItemObject(item);
        setDeleteItemName(item.title);
        setShowDeleteModal(true);
    }

    const confirmDeleteItem = async () => {
        const listRef = doc(db, 'orderList', 'listJuly22');
        await updateDoc(listRef, {
            orderList: arrayRemove(deleteItemObject)
        });
        getOrderList();
        setShowDeleteModal(false);
        setAlert(true);
    }

    return (
        <div className="admin-order-list">
        <Link to='/admin/bestellijst/toevoegen' className='item add'>
            <i className='fa-solid fa-plus'></i>
            <h1>Artikelen aan bestellijst toevoegen</h1>
        </Link>
        <div className='item search-container'>
            <div className='search-bar'>
                <i className='fa-solid fa-search'></i>
                <input type='text' onChange={(e) => {setSearchValue(e.target.value)}} value={searchValue}/>
            </div>
        </div>
            {
                orderList.map((item, i) => {
                    return (
                        (item.title.toLowerCase().includes(searchValue.toLowerCase()) || 
                        item.description.toLowerCase().includes(searchValue.toLowerCase())) &&
                        <div className='item'>
                            <div className='section description'>
                                <h3>
                                    {item.title}
                                </h3>
                                <p>
                                    {item.description}
                                </p>
                            </div>
                            <p className='vertical-rule'> | </p>
                            <div className='section portion'>
                                <p>{renderPortion(item.amount, item.portionType)}</p>
                            </div>
                            <p className='vertical-rule'> | </p>
                            <div className='section price'>
                                <p>{renderPrice(item.price)}</p>
                            </div>
                            <div className='section options'>
                                <i className='fa-solid fa-edit'></i>
                                <i className='fa-solid fa-copy'></i>
                                <i className='fa-solid fa-trash-can' onClick={() => {deleteItem(item)}}></i>
                            </div>
                        </div>
                    );
                })
            }
            <div className={`modal delete-modal ${!showDeleteModal && 'hidden'}`}>
                <h2>Weet je zeker dat je '{deleteItemName}' wilt verwijderen?</h2>
                <div className='horizontal-rule'/>
                <div className='buttons'>
                    <button onClick={() => {setShowDeleteModal(false)}}>Annuleren</button>
                    <button onClick={() => {confirmDeleteItem()}} >Verwijderen</button>
                </div>
            </div>
            <div className={`modal-overlay ${!showDeleteModal && 'hidden'}`} />
            <Alert alert={alert} setAlert={setAlert}>
                {deleteItemName} verwijderd
            </Alert>
        </div>
    );
}

export default AdminOrderList;