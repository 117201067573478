import './Alert.css';

import { useEffect, useState } from 'react';

const Alert = props => {

    const [counter, setCounter] = useState(0);
    const [alertHidden, setAlertHidden] = useState(true);

    let timeout;
    const showAlert = () => {
        setAlertHidden(false);
        timer();
        timeout = setTimeout(() => {
            setAlertHidden(true);
            clearInterval(interval);
            clearTimeout(timeout);
            props.setAlert(false);
            setCounter(0);
        }, 5000);
    }

    var interval;
    const timer = () => {
        interval = setInterval(function(){
            setCounter((prevState) => prevState + .2);
        }, 10);
    }

    const alertHiddenToggle = () => {
        clearInterval(interval);
        clearTimeout(timeout);
        setAlertHidden(!alertHidden);
        props.setAlert(false);
        setCounter(0);
    }

    useEffect(() => {
        props.alert && showAlert();
    }, [props.alert])

    return (
        <div className={`alert ${alertHidden && 'hidden'}`}>
            <i className='fa-solid fa-times close' onClick={() => {alertHiddenToggle()}}></i>
            {props.children}
            <div className='time-bar' style={{width: `${counter}%`}} />
        </div>
    );
}

export default Alert;